var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "container mx-auto grid grid-cols-3 gap-6" },
      [
        _vm.items && _vm.items.length
          ? _vm._l(_vm.items, function (item, index) {
              return _c("MovieItem", {
                key: index,
                staticClass: "col-span-1 items-strech",
                attrs: { value: item.movie },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function () {
                        return [
                          _c(
                            "p",
                            { staticClass: "text-2xl mt-3 break-words pb-8" },
                            [_vm._v(_vm._s(item.movie.film_name))]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "text-base font-semibold break-words absolute bottom-0",
                            },
                            [_vm._v(_vm._s(item.movie.director))]
                          ),
                          _c("p", { staticClass: "text-base" }, [
                            _vm._v(
                              _vm._s(_vm.$t("pages.favorits.added_at")) +
                                " " +
                                _vm._s(_vm._f("date")(item.added_at))
                            ),
                          ]),
                          _c(
                            "button",
                            {
                              staticClass:
                                "text-center rounded px-3 py-1 text-sm bg-base-yellow my-2",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.removeFromFavorite(index)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("pages.favorits.remove")) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              })
            })
          : _c(
              "div",
              {
                staticClass:
                  "col-start-1 col-span-12 w-full bg-very-light-grey rounded text-center p-2",
              },
              [_c("p", [_vm._v(_vm._s(_vm.$t("pages.favorits.none")))])]
            ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }